<template>
  <div>
    <div class="row w-100">
      <strong class="ml-1 mt-1 text-info"
        ><div>
          Your subscription will renew automatically every year with a one-time
          payment of {{ subscriptionInfo.total }} EUR. You may cancel your
          subscription any time in the Subscription section of your profile. By
          clicking
          {{ isUpgrade ? '"Upgrade subscription"' : '"Confirm and pay"' }}, you
          agree to the Terms and Conditions.
        </div>
      </strong>
      <b-button
        class="w-100 my-2 ml-1"
        variant="success"
        :disabled="loading"
        @click="onCheckout()"
      >
        {{ isUpgrade ? "Upgrade subscription" : "Confirm and pay" }}
        <b-spinner v-if="loading" type="grow" small label="Loading..." />
      </b-button>
      <router-link to="/" class="col-12">
        <b-button class="w-100" variant="ghost-success" :disabled="loading">
          BACK <b-spinner v-if="loading" type="grow" small label="Loading..."
        /></b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";
import { mapActions } from "vuex";

export default {
  name: "PaymentSection",
  components: {
    BButton,
    BSpinner,
  },
  mounted() {
    this.subscriptionInfo;
  },
  computed: {
    subscriptionInfo() {
      return this.$store.getters["channelSelector/getSubscriptionInfo"];
    },
    isUpgrade() {
      return this.$store.getters["channelSelector/getIsUpgrade"];
    },
    loading() {
      return this.$store.getters["channelSelector/getLoading"];
    },
    userData() {
      return this.$store.getters["auth/authUser"];
    },
  },
  methods: {
    ...mapActions({
      onCheckout: "channelSelector/checkout",
    }),
  },
};
</script>

<style scoped></style>
