<template>
  <div
    class="d-flex flex-column-reverse flex-md-row flex-lg-row justify-content-center bg-white shadow h-75 pay-relative"
  >
    <portal-target name="bottom" class="pay-button"></portal-target>
    <slot name="body"></slot>
  </div>
</template>

<script>
import { PortalTarget } from "portal-vue";

export default {
  components: {
    PortalTarget,
  },
};
</script>

<style scoped lang="scss">
.d-flex {
  height: 85vh;
  overflow: auto;
}

.pay-relative {
  position: relative;
}

.pay-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}
</style>
