<template>
  <span>
    <feather-icon
      class="text-info ml-1"
      icon="InfoIcon"
      size="20"
      :id="`tooltip-channel-${title}`"
    />
    <b-tooltip
      v-for="item in paymentInfo.items"
      v-bind:key="item.name"
      :target="`tooltip-channel-${title}`"
      triggers="hover"
      variant="info"
    >
      <b>Payment fee</b>
      <p>
        {{ currency }}{{ paymentExtraFee }} + {{ paymentExtraFeePercent }}% of
        the price
      </p>
    </b-tooltip>
  </span>
</template>
<script>
import { BTooltip } from "bootstrap-vue";
import { YEAR_INTERVAL_UNIT } from "@/store/channel-selector";
export default {
  components: { BTooltip },
  props: {
    paymentExtraFee: {
      require: false,
      default: 0,
    },
    paymentExtraFeePercent: {
      require: false,
      default: 0,
    },
    currency: {
      type: String,
    },
    title: {},
    paymentInfo: {
      type: Object,
    },
    intervalUnit: {
      type: String,
    },
  },
  computed: {
    intervalUnitMultiplier() {
      return this.intervalUnit === YEAR_INTERVAL_UNIT ? 12 : 1;
    },
  },
};
</script>
