<template>
  <BillingContainer>
    <template slot="body">
      <checkout-information />
    </template>
  </BillingContainer>
</template>

<script>
import BillingContainer from "@/layouts/components/billing/BillingContainer";
import CheckoutInformation from "@/layouts/components/checkout/CheckoutInformation";

export default {
  components: {
    CheckoutInformation,
    BillingContainer,
  },
};
</script>

<style></style>
