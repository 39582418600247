<template>
  <div class="d-flex flex-column justify-content-start align-items-between">
    <billing-info-item
      title="Upgrade details"
      :payment-label="upgradeFeeLabel"
      v-if="isUpgrade"
      :interval-unit="intervalUnit"
      :currency="currency"
      :payment-info="upgradeInfo"
      :payment-extra-fee="paymentExtraFee.toFixed(2)"
      :payment-extra-fee-percent="paymentExtraFeePercent"
    />
    <billing-info-item
      title="Subscription details"
      :payment-label="subscriptionFeeLabel"
      :interval-unit="intervalUnit"
      :currency="currency"
      :payment-info="subscriptionInfo"
      :payment-extra-fee="paymentExtraFee.toFixed(2)"
      :payment-extra-fee-percent="paymentExtraFeePercent"
    >
      <template #period> / {{ intervalUnitLabel }} </template>
    </billing-info-item>
  </div>
</template>

<script>
import { YEAR_INTERVAL_UNIT } from "@/store/channel-selector";
import BillingInfoItem from "@/layouts/components/billing/BillingInfoItem.vue";
import { mapActions } from "vuex";
import {
  PAYMENT_EXTRA_FEE_IN_EUR,
  PAYMENT_EXTRA_FEE_PERCENT,
} from "@/services/utils/util";

export default {
  components: {
    BillingInfoItem,
  },
  mounted() {
    this.getSysConfig().catch(console.error);
  },
  computed: {
    subscriptionFeeLabel() {
      return this.isUpgrade
        ? `Subscription fee (From:  ${this.subscriptionFrom})`
        : `You pay (subscription fee from  ${this.subscriptionFrom})`;
    },
    upgradeFeeLabel() {
      return `You pay (upgrade fee from now to ${this.activeUntil})`;
    },
    currency() {
      const cartInfo = this.$store.getters["channelSelector/getCartInfo"];
      return cartInfo?.cartInfo?.currency?.name ?? "EUR";
    },
    isUpgrade() {
      return this.$store.getters["channelSelector/getIsUpgrade"];
    },
    activeUntil() {
      const finishedAt = this.$store.getters["auth/activeUntil"];
      if (!finishedAt) return;
      return this.$dateHelpers.formatDate(finishedAt);
    },
    subscriptionFrom() {
      const finishedAt = this.$store.getters["auth/activeUntil"];
      if (!finishedAt) return this.$dateHelpers.formatDate(new Date());
      const nextDay = this.$dateHelpers.addDays(new Date(finishedAt));
      return this.$dateHelpers.formatDate(nextDay);
    },
    upgradeInfo() {
      return this.$store.getters["channelSelector/getUpgradeInfo"];
    },
    subscriptionInfo() {
      return this.$store.getters["channelSelector/getSubscriptionInfo"];
    },
    intervalUnit() {
      return this.$store.getters["channelSelector/getIntervalUnit"];
    },
    intervalUnitLabel() {
      return this.intervalUnit === YEAR_INTERVAL_UNIT ? "year" : "month";
    },
    paymentExtraFee() {
      return this.$store.getters["adminSystemConfig/getSysConfigValue"](
        PAYMENT_EXTRA_FEE_IN_EUR
      );
    },
    paymentExtraFeePercent() {
      return this.$store.getters["adminSystemConfig/getSysConfigValue"](
        PAYMENT_EXTRA_FEE_PERCENT
      );
    },
  },
  methods: {
    ...mapActions({
      getSysConfig: "adminSystemConfig/getSysConfig",
    }),
  },
};
</script>
