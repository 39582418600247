<template>
  <b-col
    md="7"
    lg="9"
    class="d-flex justify-content-center align-items-center flex-md-row align-items-md-start justify-content-md-start flex-lg-row align-items-lg-start justify-content-lg-start col-12 px-md-2 px-lg-2 py-3"
  >
    <b-col sm="12" md="12" lg="12" class="mx-auto">
      <billing-total />
      <payment-section />
    </b-col>
  </b-col>
</template>

<script>
import { BCol } from "bootstrap-vue";
import PaymentSection from "@/layouts/components/checkout/PaymentSection";
import BillingTotal from "../billing/BillingTotal.vue";

export default {
  components: {
    PaymentSection,
    BCol,
    BillingTotal,
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.switch:hover {
  cursor: pointer;
}
</style>
